@include add-foundation-colors;

$addis-grey: $light-gray;
$addis-grey-border: $medium-gray;
#hero {
  background: $drugis-color;
  height: 420px;
  text-align: left;
  position: relative;
  padding: 100px 20px 0 20px;
}

.right {
  float:right;
}

.color-stripe {
  background: $drugis-color;
  height: 0.25rem;
}

// header ////
.user-image {
  margin-top: -1px;
  margin-right: 4px;
  height: 32px;
  border-radius: 15px;
}

.inline-header {
  display: inline;
}

.indent-one {
  margin-left: 2rem;
}

a.manual:hover {
  text-decoration: underline;
}

.menu a.manual {
  line-height:2rem;
}

a.manual:visited {
  color: $white;
}

.alert {
  color: $alert-color;
}

.warning {
  color: $warning-color;
}

.info {
  color: darken(#A0D3E8, 10%);
}

.error-message {
  display: block;
  color: $drugis-color;
}

.alert-box-message {
  padding-right: 1.25rem;
}

body.test {
  background-image: url("../img/test.png");
  background-repeat: repeat-x repeat-y;
}

/// tables ///
//////// Tables /////////////////
table {
  caption {
    text-align: left;
    font-weight: lighter;
  }
  border-collapse: collapse; //remove default foundation striping
  tr:nth-of-type(even) {
    background-color: transparent;
  }
  tr {
    height: 1rem;
    th.sortable {
      cursor: pointer;
    }
    th {
      text-align: center;
      background-color: #F5F5F5;
    }
    th,
    td {
      border: solid 1px $addis-grey-border;
      a:hover {
        text-decoration: underline;
      }
    }
    td.button-cell {
      text-align: center;
      button {
        margin-bottom: 0;
        font-size: $global-font-size;
      }
    }
    td.input-cell {
      padding: 0;
      select,
      input[type="number"] {
        margin: 0;
        border: 0;
        text-align: center;
      }
      input[type="number"].ng-invalid,
      input[type="number"].ng-invalid-number,
      input[type="number"].invalid-trialverse-number {
        background-color: lighten($alert-color, 10%);
      }
    }
  }
}


.legend-table tbody {
  border: none;
}

.legend-table td {
  background: #333333;
  border: none;
}

table tr td.input-cell-leftpad {
  margin: 0;
  padding: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

table tr td.input-cell input[type="text"] {
  margin: 0;
  padding: 0;
  padding-left:0.5rem;
}


tr.disabled-row td {
  background: lightgrey;
  color: #666;
}

tr.clickable td {
  color: $primary-color;
}

td.empty-cell {
  background-color: $addis-grey-border;
}

// lighten the darken on hover
tr.on-hover:hover {
  background: darken($secondary-color, 0.5%);
  cursor: pointer;
  text-decoration: none;
}

table.layout-table {
  border: none;
  background-color: #F3F0FA;
  tbody {
    background-color: #F3F0FA;
  }
  td {
    border: none;
  }
}

.noborder {
  border: none;
}

.callout.primary {
  background-color: #F3F0FA;
  color: #333333
}

.callout.alert {
  background-color: #e66464
}

//////////////
.error-message {
  display: block;
  color: $drugis-color;
}

.application-link a {
  text-decoration: underline;
}

// needed to show part below descender line
input[type="number"],
input[type="text"],
select,
select option {
  line-height: 1.1rem;
}

/////////////////////////// top nav bar
.menu a,
.menu a:hover,
.menu a:active {
  color: white;
}

.breadcrumbs {
  background: #f4f4f4;
  padding: 0.4rem 0.7rem;
}

.bar-padding {
  padding-top: 0.61rem;
  padding-bottom: 0.61rem;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button.info {
  background-color: #a0d3e8;
  border-color: #61b6d9;
  color: #333333;
}

button.info:hover {
  color: #FFFFFF;
  background-color: #61b6d9;
}

.breadcrumbs.fixed {
  margin-top: 57px;
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 2px 4px rgba(0, 0, 0, 0.28);
  border-bottom: 1px solod rgba(0, 0, 0, 0.14);
  z-index: 50;
}

////// end top nav bar
#hero h1 {
  font-size: 4em;
  color: white;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 100%;
}

#hero h1 small {
  color: white;
}

#hero h4 {
  font-size: 1.35em;
  color: white;
  font-weight: 300;
}

header {
  background: $drugis-color;
  text-align: left;
  position: relative;
  padding: 25px 20px 35px 0px;
}

header h2 {
  font-size: 3.5em;
  color: white;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 100%;
}

header h4 {
  color: white;
}

header small {
  color: white;
}

.content {
  padding-top: 20px;
}

h3 img {
  float: right;
  margin-right: 10px;
}

.row-divider {
  margin: 2rem 0;
}

.screen-shot {
  border: 1px solid #dddddd;
  padding: 4px;
  margin: 0.5rem;
}

.footer {
  background: #333333;
  padding: 24px 0 10px;
}

.footer a,
.footer h4,
.footer p {
  color: #666666
}

.footer a:hover {
  color: #777777
}

.f-topbar-fixed {
  margin-top: 32px;
  padding-top: 4rem;
}

.fixed {
  width: 100%;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 99;
}

/* ---------sticky footer --------------*/

$sticky-footer-height: 4.5rem;
* {
  margin: 0;
}

html,
body {
  height: 100%;
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -4rem;
}

.push {
  height: $sticky-footer-height;
}

.footer {
  height: $sticky-footer-height;
}

.footer,
.push {
  clear: both;
}

.copyright {
  display: inline;
}

/* -------- end sticky footer---------*/

#map_canvas {
  height: 280px;
}

/* standard */

pre {
  margin-bottom: $paragraph-margin-bottom;
  border: 1px solid darken($secondary-color, 20%);
  background-color: $light-gray;
  padding: 0.8em;
  font-size: $global-font-size;
  overflow-x: auto;
}

pre code {
  border: none;
  background: none;
  padding: 0px;
}

code {
  border: 1px solid darken($secondary-color, 20%);
  background-color: $secondary-color;
  padding: 0 0.2em 0 0.2em;
}


/* -------- inline help directive---------*/

inline-help {
  margin: 0.1rem;
}

inline-help a {
  color: grey;
}

inline-help a:hover {
  color: darken(grey, 10%);
}

.tooltip {
  max-width: 300px;
  background-color: #333333;
  font-size: 100%;
  line-height: 1.3;
  padding: 1.5rem;
}
.tooltip a {
  color:#008CBA;
}
.tooltip.bottom::before {
    border-top-style: solid;
    border-color: #333333;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
}
.tooltip.top::before {
    border-top-style: solid;
    border-color: #333333;
    top: 100%;
    border-top-color: #333333;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
}
/* -------- end inline help directive---------*/

.reveal-modal {
  overflow-x: auto;
  min-width: 540px;
}

.tooltip-content {
  max-height:300px;
  overflow-y: auto;
}

.has-tip {
  border-bottom: none
}

.tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
  background: #fefefe;
}

ul.tabs {
  border-left:0;
  border-right: 0;
  border-top: 0;
}

.tab-common {
  border: 1px solid;
  padding-top: 17px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  border-color: #e6e6e6;
  font-size: 0.75rem;
}

.tab-is-active {
  background: white;
  border-bottom: none;
}

.tab-is-inactive {
  background: #efefef;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  color: #1976D2
}

.tab-is-inactive:hover {
  background: white;
}

th.resizableColumn {
  resize: horizontal;
  overflow: hidden;
  min-width: inherit;
  min-height: 100%;
}

div.resizableColumn {
  resize: horizontal;
  overflow: hidden;
  min-width: 100%;
  min-height: 100%;
}

input.username {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  border: 1px solid #cacaca;
  border-radius: 2px;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10,10,10,.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  color: #0a0a0a;
  transition: box-shadow .5s,border-color .25s ease-in-out;
}